interface Claims {
    name?: string;
    email?: string;
}

interface DecodedToken {
    claims?: Claims;
}

interface ParsedValue {
    decodedToken?: DecodedToken;
}

export function findAuth0UserInfo(): { name?: string; email?: string } | null {
    // Check if we're in a browser environment
    if (typeof window === 'undefined' || !window.localStorage) {
        return null;
    }

    for (let i = 0; i < window.localStorage.length; i++) {
        const key = localStorage.key(i);
        if (!key) continue;

        const value = localStorage.getItem(key);
        if (!value) continue;

        try {
            const parsedValue: ParsedValue = JSON.parse(value);
            const claims = parsedValue?.decodedToken?.claims;

            if (claims) {
                const { name, email } = claims;

                if (name || email) {
                    return { name, email };
                }
            }
        } catch (e) {
            // If value is not JSON, ignore
        }
    }
    return null;
}
