import React, { useState, useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import './styles/TranscriptionPage.css';
import TimePicker from './components/TimePicker';
import AudioPlayer from './components/AudioPlayer';
import Header from './components/Header';
import TranscriptionOutput from './components/TranscriptionOutput';
import QuoteDisplay from './components/QuoteDisplay';
import Banner from './components/Banner';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from './api/axiosInstance';
import ProgressBar from 'components/ProgressBar';
import { findAuth0UserInfo } from 'utils';

interface TranscriptionChunk {
    message: string;
    timeBlock?: string;
};

const TranscriptionPage: React.FC = () => {
    const [uploading, setUploading] = useState<boolean>(false);
    const [transcription, setTranscription] = useState<TranscriptionChunk[]>([]);
    const [audioFile, setAudioFile] = useState<File | null>(null);
    const [startTime, setStartTime] = useState<string>('00:00:00');
    const [endTime, setEndTime] = useState<string>('00:10:00');
    const [audioDuration, setAudioDuration] = useState<number | null>(null);
    const [progressMessage, setProgressMessage] = useState<string>('');
    const [uploadProgress, setUploadProgress] = useState<number | null>(null);
    const [transcribing, setTranscribing] = useState<boolean>(false);
    const [transcribeWholeAudio, setTranscribeWholeAudio] = useState<boolean>(false);
    const [totalChunks, setTotalChunks] = useState<number | null>(null);
    const [chunksDone, setChunksDone] = useState<number>(0);
    const [options, setOptions] = useState({
        addPunctuations: true,
        enforceBritishEnglish: true
    });

    const userInfo = findAuth0UserInfo();

    const getAudioDuration = (file: File) => {
        const audio = new Audio(URL.createObjectURL(file));
        audio.addEventListener('loadedmetadata', () => {
            setAudioDuration(audio.duration);
            setEndTime(secondsToTime(audio.duration))
        });
    };

    const handleStartTimeChange = (newStartTime: string) => {
        const startTimeSeconds = timeToSeconds(newStartTime);
        const endTimeSeconds = timeToSeconds(endTime);

        if (startTimeSeconds >= endTimeSeconds) {
            const newEndTime = secondsToTime(audioDuration);
            setEndTime(newEndTime);
        }

        setStartTime(newStartTime);
    };

    const secondsToTime = (totalSeconds: number) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const displayToast = (type: string) => {
        switch (type) {
            case 'invalidDuration':
                toast.error('Invalid audio duration. Please select a valid range.');
                break;
            case 'missingFile':
                toast.error('Please upload an audio file.');
                break;
            case 'notAudio':
                toast.error('The selected file is not an audio file.');
                break;
            case 'fileTooLarge':
                toast.error('The selected file is too large. Please choose a file smaller than 512MB.');
                break;
            case 'uploadError':
                toast.error('Error occurred during the upload process. Please check your network and try again.');
                break;
            case 'networkError':
                toast.error('A network error occurred. Please check your internet connection and try again.');
                break;
            case 'transcriptionError':
                toast.error('Error occurred during the transcription process. Please try again.');
                break;
            default:
                toast.error('An unknown error occurred.');
        }
    };

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length === 0) {
            return;
        }

        const file = acceptedFiles[0];

        // Check if the file is an audio file
        if (!file.type.startsWith('audio/')) {
            displayToast('notAudio');
            return;
        }

        // Check if the file size is less than 512 MB
        if (file.size > 512 * 1024 * 1024) {
            displayToast('fileTooLarge');
            return;
        }

        setAudioFile(file);
        getAudioDuration(file);
    }, []);

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        onDrop,
        accept: 'audio/*' as any,
        maxSize: 512 * 1024 * 1024, // 512 MB
    });

    const timeToSeconds = (time: string) => {
        const [hours, minutes, seconds] = time.split(':').map((v) => parseInt(v, 10));
        return hours * 3600 + minutes * 60 + seconds;
    };

    const updateChunk = (chunks, totalSentChunks: number, timeBlock: string) => {
        const chunkResults: TranscriptionChunk[] = chunks.map(chunk => ({
            message: chunk.result,
            timeBlock: chunk.timeBlock

        }));
        if(chunkResults) {
            setTranscription(chunkResults)
        }
        const chunksCompleted = chunks.filter(chunk => chunk.status === "completed")
        setChunksDone(chunksCompleted.length);
        setTotalChunks(totalSentChunks);
    }

    const pollTranscriptionStatus = async (trackerId: string) => {
        try {
            const response = await axiosInstance.get(`${process.env.REACT_APP_API_ENDPOINT}/api/transcription-status/${trackerId}`);
            const { status, chunks, error, done, totalChunks: totalSentChunks, timeBlock } = response.data;

            if (status === 'completed' && done) {
                toast.success('Transcription complete.');
                updateChunk(chunks, totalSentChunks, timeBlock)
                resetLoadingState();
            } else if (status === 'completed' || status === 'processing') {
                updateChunk(chunks, totalSentChunks, timeBlock)
                if (!done) {
                    setTimeout(async () => await pollTranscriptionStatus(trackerId), 10000);
                }
            } else if (status === 'error') {
                displayToast('transcriptionError');
                if (error) {
                    console.error(error);
                }
                resetLoadingState();
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                displayToast('networkError');
            } else {
                displayToast('transcriptionError');
                if (error.message) {
                    toast.error(error.message);
                }
            }
            resetLoadingState();
        }
    };

    const transcribeAudio = async () => {
        if (!isTranscribeButtonEnabled()) {
            if (!audioFile) {
                displayToast('missingFile');
            } else {
                displayToast('invalidDuration');
            }
            return;
        }
        toast.dismiss();
        clearTranscription();
        try {
            setUploading(true);
            setProgressMessage('Uploading...');

            const formData = new FormData();
            audioFile && formData.append('file', audioFile);
            if(!transcribeWholeAudio) {
                formData.append('startTime', timeToMinutesAndSeconds(startTime));
                formData.append('endTime', timeToMinutesAndSeconds(endTime));
            }
            formData.append('addPunctuations', String(options.addPunctuations));
            formData.append('enforceBritishEnglish', String(options.enforceBritishEnglish));
            formData.append('transcribeWhole', String(transcribeWholeAudio));
            if(userInfo) {
                formData.append('email', userInfo.email);
                formData.append('name', userInfo.name);
            }

            const response = await axiosInstance.post(`${process.env.REACT_APP_API_ENDPOINT}/api/transcribe`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: (progressEvent) => {
                    const progressPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(progressPercentage);
                    if (progressPercentage === 100) {
                        setProgressMessage('Transcribing...');
                        setUploadProgress(null);
                        setTranscribing(true);
                    }
                },
            });

            if (response.data.trackerId) {
                setTotalChunks(response.data.totalChunks);
                setTimeout(async () => await pollTranscriptionStatus(response.data.trackerId), 5000);
            } else {
                displayToast('transcriptionError');
                resetLoadingState();
            }
        } catch (error) {
            if (error.message === 'Network Error') {
                displayToast('networkError');
            } else if (progressMessage === 'Uploading...') {
                displayToast('uploadError');
            } else {
                displayToast('transcriptionError');
                if (error.message) {
                    toast.error(error.message);
                }
            }
            resetLoadingState();
        }
    };

    const resetLoadingState = () => {
        setUploadProgress(null);
        setTranscribing(false);
        setUploading(false);
        setProgressMessage('');
        setTotalChunks(null);
        setChunksDone(0);
    };

    const timeToMinutesAndSeconds = (time) => {
        const totalSeconds = timeToSeconds(time);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const clearTranscription = () => {
        setTranscription([]);
    };

    const isTranscribeButtonEnabled = () => {
        if (transcribeWholeAudio) {
            return audioFile !== null;
        } else {
            const startTimeSeconds = timeToSeconds(startTime);
            const endTimeSeconds = timeToSeconds(endTime);
            return (
                audioFile &&
                startTime &&
                endTime &&
                endTimeSeconds - startTimeSeconds > 0 &&
                endTimeSeconds <= (audioDuration || Infinity)
            );
        }
    };

    const progressBarUpload = useMemo(
        () => (
            <ProgressBar
                progress={uploadProgress}
                label={`Uploading... ${uploadProgress}%`}
                indeterminate={false}
            />
        ),
        [uploadProgress]
    );

    const progressBarTranscribe = useMemo(
        () => (
            <ProgressBar
                progress={100}
                label={(totalChunks) ? `Transcribing... ${chunksDone} out of ${totalChunks} done` : 'Transcribing...'}
                indeterminate={true}
            />
        ),
        [chunksDone, totalChunks]
    );

    return (
        <>
            <Header />
            <ToastContainer />
            <Banner message="You are now able to transcribe large audio duration or even the entire message. Transcription results will be sent in chunks of 20 minutes." /> {/* Add the Banner here */}
            <div className="container mx-auto py-12">
                {transcribing ? (
                    <QuoteDisplay />
                ) : (
                    <div className="bg-blue-100 border-t border-b border-blue-500 my-4 text-blue-700 px-4 py-3" role="alert">
                        <p className="font-bold">NOTE:</p>
                        <p className="text-sm">This app is meant to be used for Sola Areogun Ministry related tasks <strong>ONLY</strong>. Any other use is prohibited and will be frowned upon!</p>
                        <p className="text-sm">Uploaded audio files will be reviewed regularly to ensure compliance.</p>
                    </div>
                )}
                <div
                    {...getRootProps()}
                    className={`dropzone p-6 border-2 border-dashed rounded ${isDragActive ? 'border-green-500' : isDragReject ? 'border-red-500' : 'border-gray-300'
                        }`}
                >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <p>Drop the audio file here...</p>
                    ) : audioFile ? (
                        <p>Selected file: {audioFile.name}</p>
                    ) : (
                        <p>Drag and drop an audio file here, or click to select a file</p>
                    )}
                </div>
                <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-8 mt-2">
                    <TimePicker
                        id="start-time"
                        label="Start Time:"
                        value={startTime}
                        onChange={handleStartTimeChange}
                        maxDuration={audioDuration || Infinity}
                        disabled={transcribeWholeAudio}
                    />
                    <TimePicker
                        id="end-time"
                        label="End Time:"
                        value={endTime}
                        onChange={setEndTime}
                        maxDuration={audioDuration || Infinity}
                        disabled={transcribeWholeAudio}
                    />
                </div>
                {audioFile && <AudioPlayer audioFile={audioFile} startTime={startTime} endTime={endTime} />}
                <div className="flex flex-col md:flex-row options-section mt-6 space-x-6 items-center md:items-start justify-center space-y-2 md:space-y-0 md:space-x-4">

                    <label className="flex items-center">
                        <input
                            type="checkbox"
                            className="form-checkbox h-5 w-5 text-blue-500 rounded"
                            checked={options.addPunctuations}
                            onChange={(e) => setOptions((prev) => ({ ...prev, addPunctuations: e.target.checked }))}
                        />
                        <span className="ml-2 text-gray-700">Add punctuations</span>
                    </label>
                    <label className="flex items-center">
                        <input
                            type="checkbox"
                            className="form-checkbox h-5 w-5 text-blue-500 rounded"
                            checked={options.enforceBritishEnglish}
                            onChange={(e) => setOptions((prev) => ({ ...prev, enforceBritishEnglish: e.target.checked }))}
                        />
                        <span className="ml-2 text-gray-700">Enforce British English</span>
                    </label>
                </div>
                <button
                    className="mt-4 bg-blue-500 text-white font-bold py-2 px-4 rounded"
                    onClick={transcribeAudio}
                    disabled={uploading || transcribing}
                >
                    {(uploading && progressMessage) ? progressMessage : 'Transcribe'}
                </button>
                {transcribing ? (
                    <span className="ml-4 text-sm text-orange-400 font-semibold">Transcribing long audio may take some time. Please, be patient.</span>
                ): null}

                {uploadProgress !== null && progressBarUpload}
                {transcribing && progressBarTranscribe}

                {transcription?.length ? (
                    transcription.map((transcript, index) => (
                        <TranscriptionOutput key={index} index={index} timeBlock={transcript.timeBlock} transcription={transcript.message} clearTranscription={clearTranscription} />
                    ))
                ): null}
            </div>
        </>
    );
};

export default TranscriptionPage;
